import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import icon from "../assets/logo.svg";
import fbicon from "../assets/fbicon.png";
import twicon from "../assets/twicon.png";
import lnicon from "../assets/lnicon.png";
import instagramicon from "../assets/insicon.png";
import whatsicon from "../assets/whatappin.png";

const Footer = () => {
  const {t} = useTranslation("footer");

  return (
    <footer className="text-white body-font bg-[#111827]">
      <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-wrap md:text-left text-center justify-between">
          <div className="w-full lg:w-2/5 mb-2 lg:mb-0">
            <Link to="/" className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
              <img src={icon} alt={t("HUMD logo")} height={190} width={190} className="pb-2 mx-auto lg:mx-0" />
            </Link>
            <p className="text-gray-400 text-sm lg:w-2/3 mx-auto lg:mx-0">
              {t("HUMD is GCC's largest printing marketplace to customize & print online. Business cards, corporate gifts, t-shirts & more. Explore beyond the print shop")}{" "}
              <a href="https://humd.ae/" className="text-green-600" target="_blank" rel="noreferrer">
                {t("now!")}
              </a>
            </p>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mb-2 sm:mb-0">
            <h2 className="title-font font-medium text-white tracking-widest text-lg mb-3">{t("Solutions")}</h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/enterprise" className="text-gray-400 hover:text-white" onClick={() => window.scrollTo(0, 0)}>
                  {t("Enterprise")}
                </Link>
              </li>
              <li className="py-2">
                <Link to="/vendors" className="text-gray-400 hover:text-white" onClick={() => window.scrollTo(0, 0)}>
                  {t("Vendors")}
                </Link>
              </li>
              <li>
                <Link to="/reseller" className="text-gray-400 hover:text-white" onClick={() => window.scrollTo(0, 0)}>
                  {t("Resellers")}
                </Link>
              </li>
            </nav>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mb-2 sm:mb-0">
            <h2 className="title-font font-medium text-white tracking-widest text-lg mb-3">{t("Useful Links")}</h2>
            <nav className="list-none mb-10">
              <li>
                <Link to="/blog" className="text-gray-400 hover:text-white">
                  {t("Blogs")}
                </Link>
              </li>
              <li className="py-1">
                <Link to="/culture" className="text-gray-400 hover:text-white">
                  {t("Culture")}
                </Link>
              </li>
              <li className="py-1">
                <Link to="/job" className="text-gray-400 hover:text-white">
                  {t("Jobs")}
                </Link>
              </li>
              <li>
                <Link to="/suntainablity" className="text-gray-400 hover:text-white">
                  {t("Sustainability")}
                </Link>
              </li>
            </nav>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/5 mb-2 sm:mb-0">
            <h2 className="title-font font-medium text-white tracking-widest text-lg mb-3">{t("Company")}</h2>
            <nav className="list-none mb-10">
              <li className="py-1">
                <Link to="/about" className="text-gray-400 hover:text-white">
                  {t("About Us")}
                </Link>
              </li>
              <li>
                <Link to="/returns" className="text-gray-400 hover:text-white">
                  {t("Returns")}
                </Link>
              </li>
              <li className="py-1">
                <Link to="/terms-of-service" className="text-gray-400 hover:text-white">
                  {t("Terms of Service")}
                </Link>
              </li>
              <li className="py-1">
                <Link to="/privacy-policy" className="text-gray-400 hover:text-white">
                  {t("Privacy Policy")}
                </Link>
              </li>
            </nav>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between items-center border-t border-gray-200 pt-4">
          <a href="https://humd.ae/" target="_blank" rel="noreferrer">
            <p className="text-gray-400 text-lg text-center sm:text-left">© HUMD</p>
          </a>
          <div className="flex justify-center mt-4 sm:mt-0">
            <a href="https://wa.me/971504630141" target="_blank" className="ml-3 bg-[#2F9E5C] rounded-full p-2" rel="noreferrer">
              <img src={whatsicon} alt="whatsapp" className="w-6 h-6" />
            </a>
            <a href="https://www.instagram.com/humd.co" className="ml-3" target="_blank" rel="noreferrer">
              <img src={instagramicon} alt="instagram" className="w-10 h-10" />
            </a>
            <a href="https://www.facebook.com/humdglobal" className="ml-3" target="_blank" rel="noreferrer">
              <img src={fbicon} alt="facebook" className="w-10 h-10" />
            </a>
            <a href="https://twitter.com/humdco" className="ml-3" target="_blank" rel="noreferrer">
              <img src={twicon} alt="twitter" className="w-10 h-10" />
            </a>
            <a href="https://bd.linkedin.com/company/humdco?trk=public_profile_experience-item_profile-section-card_subtitle-click" className="ml-3" target="_blank" rel="noreferrer">
              <img src={lnicon} alt="linkedin" className="w-10 h-10" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
